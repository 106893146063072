<template>
    <label :for="inputId" :title="dica" class="input-arquivo">
        <span class="input-arquivo-nome-arquivo">{{ textoExibicao }}</span>
        <i class="input-arquivo-icone fas fa-paperclip"></i>
        <input :id="inputId" ref="inputRef" type="file"  @change="uploadArquivo" class="input-arquivo-input" formenctype="multipart/form-data" multiple/>
    </label>
</template>

<script>

export default {
    name: 'InputArquivo',

    props: {
        inputId: {
            type: String,
            default: 'input-arquivo-id-default'
        },
        placeholder: {
            type: String,
            default: 'Selecionar arquivo...'
        },
        dica: {
            type: String,
            default: 'Abrir arquivo'
        }
    },

    data() {
        return {
            arquivo: null,
            nomeArquivo: '',
            tempUrl: '',
        }
    },

    computed: {
        textoExibicao() {
            if(!this.nomeArquivo) {
                return this.placeholder
            }
            const TAMANHO_MAX = 20
            if(this.nomeArquivo.length > TAMANHO_MAX) {
                return '...' + this.nomeArquivo.slice(this.nomeArquivo.length - TAMANHO_MAX)
            }
            return this.nomeArquivo
        }
    },

    methods: {
        uploadArquivo(event) {
            let arq = event.target.files[0];
            if(!arq) {
                return
            }
            this.arquivo = arq
            this.nomeArquivo = arq.name
            this.tempUrl = URL.createObjectURL(arq)
            this.$emit('upload', this.arquivo, this.tempUrl)
        },
        clear() {
            this.$refs.inputRef.value = null
            this.arquivo = null
            this.tempUrl = ''
            this.nomeArquivo = ''
        }
    }
}
</script>

<style scoped>
.input-arquivo {
    background-color: var(--cor-primaria-cliente);
    color: #fff;
    cursor: pointer;
    margin-bottom: 0;
    padding: 8px 12px;
    font-size: 12px;
}

.input-arquivo-nome-arquivo {
    display: inline-block;
    padding-right: 6px;
    min-width: 10rem;
}

.input-arquivo-input {
    display: none
}

</style>